import React from 'react';
import Stamp from '../components/Stamp';
import BarPagination from '../components/BarPagination';
import {Link} from 'react-router-dom';

export default class HomePage extends React.Component {
    render() {
        return (
            <div class="home-page container" style={{overflow: (this.props.inView && window.innerWidth < 1000)  ? "auto" : "hidden"}}>
                <div className="home-header">
                    <h2 className="welcome">
                        <span className="separator"></span>
                        {this.props.lang === "ro" ? "bine ai venit" : "welcome"}
                        <span className="separator"></span>
                    </h2>
                    <div
                        className="logo-container">
                    </div>
                    <button 
                        onClick={() => {this.props.onSectionClick("menu")}} 
                        className="button menu orange">
                        {this.props.lang === "ro" ? "meniu" : "menu"}
                    </button>
                    <button
                        onClick={() => {this.props.onSectionClick("booking")}} 
                        className="button book orange">
                        {this.props.lang === "ro" ? "rezervări" : "book"}
                    </button>
                </div>
                <div className="cow">
                    <img src="images/beef.png"></img>
                    <p className="text-pagination">
                        <span className="pagenumber">
                        00
                        </span>
                        <span className="pagename">
                        {this.props.lang === "ro" ? "despre osho" : "about osho"}
                        </span>    
                    </p>
                </div>
                <div className="footer">
                    <h1 className="home-title">
                    {
                        this.props.lang === "ro" ?
                        "LA OSHO, FRIPTURILE SUNT PASIUNEA NOASTRĂ." :
                        "at osho, we're all about steaks."
                    }
                    </h1>
                    <p className="home-description">
                    {
                        this.props.lang === "ro" ?
                        "Bucătăria noastră este deschisă. Am spart zidurile pentru a ajunge la clienții noștri și pentru a le oferi o experiență captivantă. Ne place să gătim și o facem cu toată inima." + '\n' + "Nu ne interesează experimentele suprarealiste. Fără aranjamente extravagante, fără exces de rafinament, fără bucătărie moleculară. Iubim fripturile.\n Fripturi, fragede, suculente și cu origini nobile." : 
                        "Our kitchen is open. We broke down the walls to reach out to our clients and provide them an immersive experience. We love to cook and we do it with all our heart. \n We're not into surrealistic experiments. No fancy plating, no fine dining, no molecular cuisine. We love steaks. \n Tender, juicy beef steaks of noble breeds."
                    }
                    </p>
                    <button
                        onClick={() => {this.props.nextSlide()}}                   
                        className="home-button button join-us-button-home"
                    >
                    {
                        this.props.lang === "ro" ?
                        "AVENTURAȚI-VĂ CU NOI ÎN CĂUTAREA FRIPTURII PERFECTE" :
                        "join us in the quest for the perfect steak"
                    }
                    </button>
                    <p className="footer-infos">
                    {   this.props.lang === "ro" ?
                        <React.Fragment>
                            bd. primaverii
                            <span className="separator">|</span>
                            nr. 19-21
                            <span className="separator">|</span>
                            bucurești
                        </React.Fragment> :   
                        <React.Fragment>
                            19-21
                            <span className="separator">|</span>
                            primaverii blvd.
                            <span className="separator">|</span>
                            bucharest
                        </React.Fragment>
                    }
                        <span className="separator">|</span>
                        021.568.3031
                        <span className="separator">|</span>
                        0758.758.750
                    </p>
                </div>
                <Stamp lang={this.props.lang} changeLang={this.props.changeLang}/>
                <BarPagination total={5} current={0} inView={true}/>
                {/* <div class="uk-inline language-select f32">
                    <i className="flag ro" onClick={() => {this.props.changeLang("ro")}}/>
                    <i className="flag gb" onClick={() => {this.props.changeLang("en")}}/>
                </div> */}
            </div>
        )
    }
}