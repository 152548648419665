import React from 'react';
import MenuItem from '../components/MenuItem';
import BarPagination from '../components/BarPagination';
import Stamp from '../components/Stamp';
import VanillaTilt from '../utilities/vanillaTilt';

const PREMIUM_SLIDES = [8,9,10,11,12,13];
const TITLE_TRANSLATIONS = {
    "burgeri & sandwiches": "burgers & sandwiches",
    "aperitive": "appetizers",
    "supe": "soups",
    "paste & risotto": "pasta & risotto",
    "pește & fructe de mare": "fish & seafood",
    "selecția casei": "restaurant's selection",
    "osho premium selection": "osho premium selection",
    "sua - black angus": "usa - black angus",
    "uruguay - black angus": "uruguay - black angus",
    "australia - wagyu": "australia - wagyu",
    "noua zeelandă - black angus & wagyu": "new zealand - black angus & wagyu",
    "irlanda - aberdeen angus": "ireland - aberdeen angus",
    "japonia - kobe": "japan - kobe",
    "garnituri": "side dishes",
    "selecție de vită": "beef selection",
    "vită maturată": "aged beef",
    "salate": "salads",
    "sosuri": "sauces",
}

export default class MenuPage extends React.Component {
    constructor(props){
        super(props);
        this.pageRef = React.createRef();
    }

    componentDidMount(){
        VanillaTilt.init(document.querySelectorAll('.menu-page-bg-image'), {
            max: 13,
            speed: 300,
        });
    }

    move = (type, direction) => {
        this.pageRef.current.classList.remove(`${type}-${direction}`)
        this.pageRef.current.classList.add(`${type}-${direction}`);
        setTimeout(() => {
            this.pageRef.current.classList.remove(`${type}-${direction}`);
        }, 1000);
    }

    fade = (type) => {
        this.pageRef.current.classList.add(`fade-${type}`);
        setTimeout(() => {
            this.pageRef.current.classList.remove(`fade-${type}`);
        }, 1000);
    }

    render(){
        return (
            <div 
                className={`menu-page container ${this.props.class ? this.props.class : this.props.title} ${this.props.idx === 6 ? "chef" : ""} ${this.props.idx === 15 ? "garnituri" : ""} ${this.props.idx === 14 ? "maturata" : ""} ${this.props.idx === 16 ? "salate" : ""}`} 
                ref={this.pageRef} 
                style={this.props.style}
            >
                <div className="menu-page-image">
                    <div 
                        onClick={() => {this.props.onSectionClick("home")}}
                        className="logo-container">
                    </div>
                    <button 
                        onClick={() => {this.props.onSectionClick("booking")}}
                        className="button book black">
                        {this.props.lang === "ro" ? "rezervări" : "book"}
                    </button>
                    <p className="text-pagination">
                        <span className="pagenumber">
                            {this.props.idx < 9 ? '0' + (this.props.idx + 1) : (this.props.idx + 1)}
                        </span>
                        <span className="pagename">
                            {this.props.lang === "ro" ? this.props.title : TITLE_TRANSLATIONS[this.props.title]}
                        </span>    
                    </p>
                    <div className="menu-page-bg-image" 
                        style={{backgroundImage:`url(images/menu${this.props.idx + 1}.jpg)`}}
                        data-tilt data-tilt-full-page-listening data-tilt-reverse="true" data-tilt-scale="1.1"
                    >
                    </div>
                </div>
                <div className="menu-page-data">
                    <BarPagination 
                        total = {18}
                        current = {this.props.idx}
                        inView = {this.props.inView}    
                    />
                    {
                        PREMIUM_SLIDES.includes(this.props.idx + 1) ?
                        <h2 className="premium-page-title">
                            osho premium selection
                        </h2> : ""
                    }
                    <h2 className="menu-page-title">
                        {this.props.lang === "ro" ? this.props.title : TITLE_TRANSLATIONS[this.props.title]}    
                    </h2>
                    {
                        this.props.idx === 6 ?
                        <div><div className = "premium-stripe-container">
                            <img src="images/goldstripe-l.png" className="premium-stripe"></img>
                            </div>
                                <div className="chef-description">
                                    {this.props.lang === "ro" ?
                                        <p>"Cel care semnează întregul meniu Osho este Chef Iosif Ștefănescu, cu peste 25 de ani experiență în gastronomie. <br /><br /> Chef Iosif este, totodată, inițiatorul mișcării Out of Kitchen, motiv pentru care oricine se află în restaurantul Osho poate sta de vorbă cu cel care a creat un preparat care i-a plăcut.</p> :
                                        <p>"The one signing the entire Osho menu is <b>Chef Iosif Stefanescu</b>, with over 25 years of experience in gastronomy. <br /><br />Chef Iosif is also the initiator of the Out of Kitchen movement, which is why anyone enjoying a dish in Osho restaurant can talk to the person who created it.</p>}
                                </div>
                        </div> : ""
                    }
                    {
                        PREMIUM_SLIDES.includes(this.props.idx + 1) ?
                        <div className = "premium-stripe-container">
                            <img src ="images/goldstripe-s.png" className = "premium-stripe">
                            </img>
                        </div> : ""
                    }
                    <div className="meals-container">
                        <div className="meals-centering">
                        {
                            Object.values(this.props.data).map((section, idx) => {
                                return (
                                    <React.Fragment>
                                        {
                                            Object.keys(this.props.data)[0] !== "default" && !Object.keys(this.props.data)[idx].includes("footer") ? 
                                            <label className="meals-section-title">
                                                {Object.keys(this.props.data)[idx]}
                                            </label> : ""
                                        }
                                        {
                                            !Object.keys(this.props.data)[idx].includes("footer") ? 
                                            <ul className="meals-list">
                                            {
                                            section.map((meal) => {
                                                return (
                                                    <MenuItem data={meal} lang={this.props.lang}/>
                                                    )
                                                })
                                            }
                                            </ul> : ""
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        </div>
                    </div>
                    <div className="menu-page-footer">
                    {
                        this.props.lang === "ro" ? this.props.data.footer : this.props.data["footer-en"]
                    }
                    </div>
                    <div 
                        className="scroll-info"
                        onClick={() => {this.props.scroll("up")}}    
                    >
                        <i data-uk-icon="chevron-right" className="right" onClick={() => {this.props.scroll("up")}}><span>next</span></i>
                    </div>
                    {
                        (this.props.idx == 17) ?
                            <div class="end-menu-cta-container"><button class="home-button button go-back" onClick={() => { this.props.onSectionClick("home") }}>home</button><button onClick={() => { this.props.onSectionClick("booking") }} class="home-button button book" >reserve a table</button></div> : ""
                    }
                    <Stamp lang={this.props.lang} changeLang={this.props.changeLang}/>
                </div>
                
            </div>
        )
    }
}