import React from 'react';

export default class BarPagination extends React.Component {
    constructor(props){
        super(props);
        this.containerRef = React.createRef();
    }
    componentDidUpdate(prevProps, prevState){
        if (this.props.inView) {
            Array.from(this.containerRef.current.children).forEach((el, idx) => {
                el.classList.add("bar");
                if (idx === this.props.current) {
                    el.classList.add("current");
                }
            });
        }
    }
    render() {
        return (
            <div className="bar-pagination" ref={this.containerRef}>
            {
                Array.from({length: this.props.total}, (el, idx) => {
                    return <div 
                        style={{transitionDelay: `${idx / 10}s`}}
                    ></div>
                })
            }    
            </div>
        )
    }
}