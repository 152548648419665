import React, { Component } from 'react';
import data from '../data/menu.json';
import MenuPage from '../templates/MenuPage.js';

export default class Menu extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentSlide: 0,
        }
        this.canSlide = true;
        this.menuLength = Object.values(data).length;
        this.containerRef = React.createRef();
        this.pageRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
        ];
    }
    changeSection = (section) => {
        this.props.changeSection(section);
        setTimeout(() => {
            this.setState({
                currentSlide: 0,
            })
        }, 1000);
    }
    move = (type, direction) => {
        this.containerRef.current.classList.remove(`${type}-${direction}`)
        this.containerRef.current.classList.add(`${type}-${direction}`);
        setTimeout(() => {
            this.containerRef.current.classList.remove(`${type}-${direction}`);
        }, 1000);
    }
    componentDidMount(){
        let xDown = null;                                                        
        let yDown = null;

        function getTouches(evt) {
            return evt.touches;
        }                                                     

        function handleTouchStart(evt) {
            const firstTouch = getTouches(evt)[0];                                      
            xDown = firstTouch.clientX;                                      
            yDown = firstTouch.clientY;                                      
        };                                                

        let handleTouchMove = (evt) => {
            if (this.canSlide === true) {
                this.canSlide = false;
                if ( ! xDown || ! yDown ) {
                    return;
                }
                
                var xUp = evt.touches[0].clientX;                                    
                var yUp = evt.touches[0].clientY;
                
                var xDiff = xDown - xUp;
                var yDiff = yDown - yUp;
                
                if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
                    if ( xDiff > 0 ) {
                        xDown = null;
                        this.canSlide = true;
                    } else {
                        xDown = null;
                        this.scroll("down");
                    }                       
                } else {
                    if ( yDiff > 0 ) {
                        this.canSlide = true;
                        yDown = null;                                             
                    } else { 
                        yDown = null;
                        this.scroll("down");
                    }                                                                 
                }
            }
        }
            
        // this.containerRef.current.addEventListener('touchstart', handleTouchStart, false);        
        // this.containerRef.current.addEventListener('touchmove', handleTouchMove, false);
        this.containerRef.current.addEventListener("wheel", (event) => {
            if (this.canSlide === true) {
                this.canSlide = false;
                let delta;
                if (event.wheelDelta) {
                    delta = event.wheelDelta;
                } else {
                    delta = -1 * event.deltaY;
                }

                if (delta < 0 && this.state.currentSlide < this.menuLength - 1){
                    this.scroll("up");
                } else if (delta > 0 && this.state.currentSlide > 0) {
                    this.scroll("down");
                }
                else this.canSlide = true;
            }
        });
    }
    scroll = (direction) => {
        if (direction === "up" && this.state.currentSlide < this.menuLength - 1){
            this.pageRefs[2].current.move('enter', 'bottom');
            setTimeout(() => {
                this.setState({currentSlide: this.state.currentSlide + 1});
                this.canSlide = true;
            }, 1000);
        } else if (direction === "down" && this.state.currentSlide > 0) {
            this.pageRefs[1].current.move('exit', 'bottom');
            setTimeout(() => {
                this.setState({currentSlide: this.state.currentSlide - 1});
                this.canSlide = true;
            }, 1000);
        }
    }
    render(){
        return (
            <div className="container menu" ref={this.containerRef} style={this.props.style}>
            {
                Array.from({length: this.menuLength}, (el, idx) => {
                    return <MenuPage
                        title={Object.keys(data)[idx]}
                        data={Object.values(data)[idx]}
                        scroll={this.scroll}
                        idx={idx}
                        inView={this.state.currentSlide === idx && this.props.inView}
                        onSectionClick={this.changeSection}
                        lang={this.props.lang}
                        changeLang={this.props.changeLang}
                        ref={
                            idx === this.state.currentSlide ?
                            this.pageRefs[1] : 
                            (idx === this.state.currentSlide + 1 && idx < this.menuLength ? 
                             this.pageRefs[2] : null 
                            )
                        }
                        style={{
                            zIndex: ( idx === this.state.currentSlide - 1 ?
                                9000 : (idx === this.state.currentSlide ? 
                                9001 : (idx === this.state.currentSlide + 1 ? 
                                9002 : 0))
                            ),
                            display: ( idx === this.state.currentSlide - 1 ?
                                "block" : (idx === this.state.currentSlide ? 
                                "block" : (idx === this.state.currentSlide + 1 ? 
                                "block" : "none"))
                            ),  
                            top: (idx <= this.state.currentSlide ? '0' : '100vh')
                        }}
                    />
                })
            }
            </div>
        )
    }
}