import React from 'react';

export default function MenuItem(props) {
    return (
        <li className="menu-item">
            <span className="menu-item-name">
                {props.lang === "ro" ? props.data.name : (props.data.en ? props.data.en : props.data.name)}
            </span>
            {
                props.data.weight ?
                <label className="weight-label">
                    {props.data.weight}G
                </label> : ""
            }
            {
                props.data.details ?
                props.data.details.map(el => {
                    return (
                        <p className="menu-item-subtext">
                            {props.lang === "ro" ? el.name : (el.en ? el.en : el.name)}
                            {
                                el.weight ?
                                <label className="weight-label">
                                    {el.weight}G
                                </label> : ""
                            }
                        </p>
                    )
                }) : ""
            }
        </li>
    )
}