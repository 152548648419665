import React from 'react';
import HomePage from '../templates/HomePage';
import AboutPage from '../templates/AboutPage';
import Gallery from './Gallery';

export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentSlide: 0,
        }
        this.menuLength = 6;
        this.canSlide = true;
        this.containerRef = React.createRef();
        this.pageRefs = [
            React.createRef(),
            React.createRef(),
        ]
    }

    move = (type, direction) => {
        this.containerRef.current.classList.remove(`${type}-${direction}`)
        this.containerRef.current.classList.add(`${type}-${direction}`);
        setTimeout(() => {
            this.containerRef.current.classList.remove(`${type}-${direction}`);
        }, 1000);
    }
    
    nextSlide = () => {
        if (this.state.currentSlide < this.menuLength) {
            this.pageRefs[1].current.move();
			
            setTimeout(() => {
                this.setState((prevState) => {
                    return {
                        currentSlide: prevState.currentSlide + 1
                    }
                });
            }, 1000)
        }
    }

    changeSection = (section) => {
        this.props.changeSection(section);
        setTimeout(() => {
            this.setState({
                currentSlide: 0
            })
        }, 1000);
    }

    render() {
        return (
            <div class="home container" ref={this.containerRef} style={this.props.style}>
                <HomePage 
                    onSectionClick={this.props.changeSection}
                    nextSlide={this.nextSlide}
                    inView={this.state.currentSlide === 0 ? true : false}
                    lang={this.props.lang} 
                    changeLang={this.props.changeLang}
                /> 
                {
                    Array.from({length:5}, (el, idx) => {
                        return <AboutPage
                            idx={idx + 1}
                            onSectionClick={this.changeSection}
                            nextSlide={this.nextSlide}
                            inView={this.state.currentSlide === idx + 1 ? true : false}
                            lang={this.props.lang} 
                            changeLang={this.props.changeLang}
                            ref={
                                idx + 1 === this.state.currentSlide ?
                                this.pageRefs[0] : 
                                (idx + 1 === this.state.currentSlide + 1 && idx < this.menuLength - 1 ? 
                                this.pageRefs[1] : null 
                                )
                            }
                            style={{
                                zIndex: (idx + 1 === this.state.currentSlide ? 
                                    9001 : (idx + 1 === this.state.currentSlide + 1 ? 
                                    9002 : 0)
                                ),
                                top: (idx + 1 === this.state.currentSlide ? '0' : '-100vh'),
                                display: idx + 1 < this.state.currentSlide ? "none" : "block",
                            }}
                        />
                    })
                }
                <Gallery
                    ref={this.state.currentSlide === 5 ? this.pageRefs[1] : null}
                    style={{
                        zIndex: (this.state.currentSlide === 5 ? 9001 : (
                            this.state.currentSlide === 6 ? 9002 : 0
                        )),
                        top: (this.state.currentSlide === 6 ? 0 : "-100vh")
                    }}
                    changeSection={this.changeSection}
                    lang={this.props.lang} 
                ></Gallery>
            </div>
        )
    }
}