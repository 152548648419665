import React from 'react';
import VanillaTilt from '../utilities/vanillaTilt'
import axios from 'axios';


const MONTHS = [
    "january", "february", "march", "april", "may", "june", "july", "august", "september",
    "october", "november", "december"
];
const RO_MONTHS = [
    "ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august",
    "septembrie", "octombrie", "noiembrie", "decembrie"
];
const DAYS_FOR_MONTH = [
     31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31
];
const TIME_PERIODS = {
    "morning": "10-12",
    "afternoon": "12-17",
    "evening": "17-20",
    "night": "20-26",
}
const RO_TIME_PERIODS = {
    "morning": "dimineață",
    "afternoon": "după-amiază",
    "evening": "seară",
    "night": "noapte",
}
const SCHEDULE = [
    21, 21, 21, 21, 21, 22, 20
]
let year = [];

(function () {
    let starting_day = 3;
    let offset = 1;
    let copy = 3;

    for (let i = 0; i < 12; i++) {
        year[i] = new Array(42); 
        starting_day = copy;
        offset = 1;

        for (let j = 1; j < 43; j++) {
       
            if (j < starting_day) {
                
                year[i][j - 1] = DAYS_FOR_MONTH[i - 1 === -1 ? 11 : i - 1] - (starting_day - j - 1);
               
            } else if (j + 1 - starting_day < DAYS_FOR_MONTH[i]) {
                year[i][j - 1] = j + 1 - starting_day;
            } else if (j + 1 - starting_day === DAYS_FOR_MONTH[i]) {
                year[i][j - 1] = j + 1 - starting_day;
            
                copy = j % 7 + 1;
            } else {
                year[i][j - 1] = offset++;
            }
        }
    }

})();

export default class Booking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            month: new Date().getMonth(),
            selected: {
                people: 0,
                date: 0,
                timePeriod: "morning",
                time: "",
                phone: "",
                name: "",
            },
            isLoading: false
        }
        this.pageRef = React.createRef();
        this.daysRef = React.createRef();
    }

    componentDidMount() {
        VanillaTilt.init(document.querySelectorAll(".booking-bg-image"), {
            max: 13,
            speed: 300,
        });
        window.dispatchEvent(new Event('resize'));

        let xDown = null;
        let yDown = null;

        function getTouches(evt) {
            return evt.touches;
        }

        function handleTouchStart(evt) {
            const firstTouch = getTouches(evt)[0];
            xDown = firstTouch.clientX;
            yDown = firstTouch.clientY;
        };  

        let handleTouchMove = (evt) => {
           
              
                if (!xDown || !yDown) {
                    return;
                }

            var xUp = evt.changedTouches[0].pageX;
            var yUp = evt.changedTouches[0].pageY;

                var xDiff = xDown - xUp;
                var yDiff = yDown - yUp;

                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    if (xDiff > 0) {
                        console.log('right');
                        if (this.state.month < 11) 
                            { this.setState((prevState) => { return { month: Math.min(11, prevState.month + 1) } }) }
                    } else {
                        console.log('left');
                        if (this.state.month > 0 ) 
                            { this.setState((prevState) => { return { month: Math.max(0, prevState.month - 1) } }) }
                    }
                } else {
                    if (yDiff > 0) {
                        console.log('down');
                    } else {
                        console.log('up');
                    }
                }
         

        }

        this.daysRef.current.addEventListener('touchstart', handleTouchStart, false);        
        this.daysRef.current.addEventListener('touchend', handleTouchMove, false);

        //this.daysRef.current.addEventListener('itemshow', function (e) { console.log(e)}, false);
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.month !== prevState.month) {
            this.setState({
                selected: {
                    ...prevState.selected,
                    date: 0
                }
            });
        }
    }

    move = (type, direction) => {

        this.pageRef.current.classList.remove(`${type}-${direction}`)
        this.pageRef.current.classList.add(`${type}-${direction}`);
        setTimeout(() => {
            this.pageRef.current.classList.remove(`${type}-${direction}`);
        }, 1000);
    }

    selectOption = (name, value) => {
        this.setState((prevState) => {
            return {
                selected: {
                    ...prevState.selected,
                    [name]: value,
                }
            }
        });
    }

    getHoursInterval = (day) => {
        let intervalLimits = TIME_PERIODS[this.state.selected.timePeriod].split("-");
        if (this.state.selected.timePeriod === "night") {
            intervalLimits[1] = SCHEDULE[day];
        }
        let intervalLength = intervalLimits[1] - intervalLimits[0];
        let timeOfDay = intervalLimits[0] >= 12 ? "pm" : "am";
        return Array.from({ length: intervalLength * 4 + 1 }, (el, idx) => {
            if (this.state.selected.timePeriod === "morning" && day < 5) {
                //idx = 2;
            }
            return `${(intervalLimits[0] % 24 + Math.floor(idx / 4)) % 24}:${idx % 4 === 0 ? 0 : ""}${15 * (idx % 4)} ${(intervalLimits[0] % 24 + Math.floor(idx / 4)) >= 24 ? "am" : timeOfDay}`;
        });
    }

    formSubmitable = () => {

        return (this.state.selected.people !== 0 &&
            this.state.selected.date !== 0 &&
            this.state.selected.time !== "" &&
            this.state.selected.phone !== "" &&
            this.state.selected.name !== "")

    }

   

    submit = () => {

        if (this.formSubmitable()) {


            this.setState({ isLoading: true });

            axios({
                method: 'post',
                //url: 'https://www.osho-restaurant.ro/do_reservation/',
                url: '/do_reservation/',
                data: JSON.stringify(this.state),
                mode: 'no-cors'
                 })
                //.then(response => response.json())
                //.then(data => this.setState({ isLoading: false }));
                .then(response => {
                    this.props.changeSection("thanks");

                    this.state.selected.people = 0;
                    this.state.selected.date = 0;
                    this.state.selected.time = "";
                    this.state.selected.phone = "";
                    this.state.selected.name = "";
                    this.state.isLoading = false;

                    if (response.ok) {
                        return response.json();
                    } else {
                        //throw new Error('Something went wrong ...');
                        //return;
                    }
                })

                //.then(data => this.setState({ hits: data.hits, isLoading: false }))
                //.catch(error => this.setState({ error, isLoading: false }));
        }
    }

    render() {
        return (
            <div className="booking-page container" style={this.props.style} ref={this.pageRef}>
                <div className="booking-image" >
                    <div
                        onClick={() => { this.props.changeSection("home") }}
                        className="logo-container">
                    </div>
                    <button
                        onClick={() => { this.props.changeSection("menu") }}
                        className="button menu black">
                        {this.props.lang === "ro" ? "meniu" : "menu"}
                    </button>
                    <div
                        style={{
                            backgroundImage: "url(images/booking.png?v=2)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%"
                        }}
                        className="booking-bg-image"
                        data-tilt data-tilt-full-page-listening data-tilt-reverse="true" data-tilt-scale="1.1"
                    >
                    </div>
                </div>
                <div className="booking-content">
                    <h2 className="page-title">
                        {
                            this.props.lang === "ro" ?
                                "Rezervă O Masă" :
                                "Reserve Your Table"
                        }
                    </h2>
                    <div className="booking-section name">
                        <label className="booking-section-title " >
                            {this.props.lang === "ro" ? "nume" : "name"}
                        </label>
                        <div className="name-container">
                            <input
                                className="name"
                                type="text"
                                onChange={(e) => {
                                    this.selectOption("name", e.target.value);
                                }}
                                value={this.state.selected.name}
                            />
                        </div>
                    </div>
                    <div className="booking-section phone">
                        <label className="booking-section-title">
                            {this.props.lang === "ro" ? "număr de telefon" : "phone nr."}
                        </label>
                        <div className="phone-number-container">
                            <span className="prefix">
                                +40
                            </span>
                            <input
                                className="number"
                                type="text"
                                maxLength="9"
                                onChange={(e) => { this.selectOption("phone", e.target.value) }}
                                value={this.state.selected.phone}
                            />
                        </div>
                    </div>
                    <div className="booking-section people">
                        <label className="booking-section-title">
                            {this.props.lang === "ro" ? "nr. persoane" : "nr. of people"}
                        </label>
                        <div className="booking-slider people" data-uk-slider>
                            <ul class="uk-slider-items uk-child-width-1-6">
                                {
                                    Array.from({ length: 12 }, (el, idx) => {
                                        return <li
                                            class={`booking-option ${this.state.selected.people === idx + 1 ? "selected" : ""}`}
                                            onClick={(e) => { this.selectOption("people", idx + 1) }}
                                        >
                                            {idx + 1}
                                        </li>
                                    })
                                }
                            </ul>
                            <a href="#" data-uk-slider-item="previous" className="slider-control left" data-uk-icon="chevron-left"></a>
                            <a href="#" data-uk-slider-item="next" className="slider-control right" data-uk-icon="chevron-right"></a>
                        </div>
                    </div>
                    <div className="booking-section day">
                        <label className="booking-section-title">
                            {
                                this.props.lang === "ro" ?
                                    RO_MONTHS[this.state.month] :
                                    MONTHS[this.state.month]
                            }
                            <span style={{ fontSize: '13px', color: 'white', fontFamily: 'Playfair Display, sans-serif' }}> 2020</span>
                        </label>
                        <div className="booking-slider day" id="booking-slider-days" data-uk-slider={`finite: true, index: ${this.state.month}`} ref={this.daysRef}  >
                            <ul class="uk-slider-items ">
                                {
                                    Array.from({ length: 12 }, (el, idx) => {
                                        return <li class="calendar" >
                                            <div className="calendar-row">
                                                <span className="day-cell">Mon</span>
                                                <span className="day-cell">Tue</span>
                                                <span className="day-cell">Wed</span>
                                                <span className="day-cell">Thu</span>
                                                <span className="day-cell">Fri</span>
                                                <span className="day-cell">Sat</span>
                                                <span className="day-cell">Sun</span>
                                            </div>
                                            {
                                                Array.from({ length: 5 }, (el, rowIdx) => {

                                                    return <div className="calendar-row">
                                                        {
                                                            Array.from({ length: 7 }, (el, colIdx) => {
                                                                return <span
                                                                    className={`calendar-cell ${(year[idx][rowIdx * 7 + colIdx] === 14 && this.state.month == 1) ? 'disabled' : ''} ${year[idx][rowIdx * 7 + colIdx] + "" === this.state.selected.date /*&& idx === this.state.month*/ ? "selected" : ""}`}
                                                                    onClick={(e) => {
                                                                        this.selectOption("date", e.target.innerText);
                                                                    }}
                                                                > 
                                                                    {year[idx][rowIdx * 7 + colIdx]}
                                                                </span>
                                                            })
                                                        }
                                                    </div>
                                                })
                                            }
                                        </li>
                                    })
                                }
                            </ul>

                            <a
                                href="#"
                                data-uk-slider-item="previous"
                                className="slider-control left"
                                data-uk-icon="chevron-left"
                                onClick={
                                    () => { this.setState((prevState) => { console.log('prev month index is ' + prevState.month); return { month: prevState.month - 1 }     }) }
                                }

                            ></a>
                            <a
                                href="#"
                                data-uk-slider-item="next"
                                className="slider-control right"
                                data-uk-icon="chevron-right"
                                onClick={
                                    () => { this.setState((prevState) => { console.log('click next: prev month index is ' + prevState.month); return { month: prevState.month + 1 } }) }
                                }
                            ></a>
                        </div>
                    </div>
                    <div className="booking-section time">
                        <label className="booking-section-title">
                            {this.props.lang === "ro" ? "ora" : "time"}
                        </label>
                        <ul className="booking-time-periods">
                            {
                                Object.keys(TIME_PERIODS).map(el => <li
                                    className={`booking-option ${this.state.selected.timePeriod === el ? "selected" : ""}`}
                                    onClick={(e) => {
                                        this.selectOption("timePeriod", el);
                                    }}
                                >
                                    {this.props.lang === "ro" ? RO_TIME_PERIODS[el] : el}
                                </li>)
                            }
                        </ul>
                        <div className="booking-slider time" data-uk-slider>
                            <ul className="uk-slider-items uk-child-width-1-6">
                                {
                                    this.getHoursInterval(new Date(new Date().getFullYear(), new Date().getMonth(), this.state.selected.date).getUTCDay())
                                        .map((el, idx) => {
                                            return (
                                                <li
                                                    className={`booking-option ${el === this.state.selected.time ? "selected" : ""}`}
                                                    onClick={(e) => {
                                                        this.selectOption("time", e.target.innerText);
                                                    }}
                                                >
                                                    {el}
                                                </li>
                                            )
                                        })
                                }
                            </ul>
                            <a href="#" data-uk-slider-item="previous" className="slider-control left " data-uk-icon="chevron-left"></a>
                            <a href="#" data-uk-slider-item="next" className="slider-control right " data-uk-icon="chevron-right"></a>
                        </div>
                    </div>

                    <div style={{ textAlign: 'center' }}>
                        {(this.state.isLoading) ?
                            <p>Loading ...</p>
                            :
                            <button
                                className={" reserve-btn button  " + (this.formSubmitable() ? '' : 'disabled') } 
                                onClick={() => { this.submit() }}
                            >
                                {
                                    this.props.lang === "ro" ? "rezervă" : "reserve"
                                }
                            </button>
                        }

                       
                    </div>
                    <div className="booking-footer">
                        <p className="footer-infos">
                            {this.props.lang === "ro" ?
                                <React.Fragment>
                                    bd. primaverii
                            <span className="separator">|</span>
                                    nr. 19-21
                            <span className="separator">|</span>
                                    bucurești
                        </React.Fragment> :
                                <React.Fragment>
                                    19-21
                            <span className="separator">|</span>
                                    primaverii blvd.
                            <span className="separator">|</span>
                                    bucharest
                        </React.Fragment>
                            }
                            <span className="separator">|</span>
                            021.568.3031
                            <span className="separator">|</span>
                            0758.758.750
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}