import React from 'react';
import Stamp from '../components/Stamp';
import BarPagination from '../components/BarPagination';
export default class HomePage extends React.Component {
    constructor(props){
        super(props);
        this.containerRef = React.createRef();
    }

    move = (type, direction) => {
        this.containerRef.current.classList.remove(`${type}-${direction}`)
        this.containerRef.current.classList.add(`${type}-${direction}`);
        setTimeout(() => {
            this.containerRef.current.classList.remove(`${type}-${direction}`);
        }, 1000);
    }
    
    render() {
        return (
            <div class="home-page thanks-page container" style={this.props.style} ref={this.containerRef}>
                <div className="home-header">
                    <h2 className="welcome">
                        <span className="separator"></span>
                        welcome
                        <span className="separator"></span>
                    </h2>
                    <div
                        className="logo-container">
                    </div>
                    <button 
                        onClick={() => {this.props.changeSection("menu")}} 
                        className="button menu orange">
                        menu
                    </button>
                    <button
                        onClick={() => {this.props.changeSection("booking")}} 
                        className="button book orange">
                        book
                    </button>
                </div>
                <div className="cow">
                    <img src="images/beef.png"></img>
                    <p className="text-pagination">
                        <span className="pagenumber">
                        00
                        </span>
                        <span className="pagename">
                        about osho
                        </span>    
                    </p>
                </div>
                <div className="footer">
                    <h1 className="home-title">
                        THANK YOU    
                    </h1>
                    <button
                        onClick={() => {this.props.changeSection("home")}}                   
                        className="home-button button">
                        Go back
                    </button>
                    <p className="footer-infos">
                        bd. primaverii
                        <span className="separator">|</span>
                        nr. 19-21
                        <span className="separator">|</span>
                        bucurești
                        <span className="separator">|</span>
                        021.568.3031
                        <span className="separator">|</span>
                        0758.758.750
                    </p>
                </div>
                <Stamp lang={this.props.lang}/>
                <BarPagination total={5} current={0} inView={true}/>
            </div>
        )
    }
}