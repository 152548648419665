export default function(event) {
    let delta;
    
    if (event.wheelDelta) {
        delta = event.wheelDelta;
    } else {
        delta = -1 * event.deltaY;
    }
    
    if (delta < 0){
        return 1;
    } else if (delta > 0) {
        return -1;
    }
}