import React, {Component} from 'react';

import Menu from './views/Menu';
import Home from './views/Home';
import Booking from './views/Booking';
import Thanks from './views/Thanks';

export default class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      inView: "home",
      offView: "",
      lang: props.lang,
    }
    this.sectionRefs = {
      "home": React.createRef(),
      "menu": React.createRef(),
      "booking": React.createRef(),
      "thanks": React.createRef(),
    }
  }

  componentDidMount(){
    document.querySelectorAll('.no-display').forEach(el => {
      el.style.display = "none";
    })
  }

  changeLang = (lang) => {
    this.setState({
      lang: lang
    });
  }

  changeSection = (newSection) => {
    this.setState({
      offView: newSection,
    });
    switch(newSection){
      case 'home':
        this.sectionRefs[this.state.inView].current.move("enter", "top");
        break;
      case 'menu':
        if(this.state.inView === "home") {
          this.sectionRefs["menu"].current.move("exit", "top");
        } else if (this.state.inView === "booking") {
          this.sectionRefs["menu"].current.move("exit", "top");
        }
        break;
      case 'booking':
        if(this.state.inView === "menu") {
          this.sectionRefs["menu"].current.move("enter", "top");
        }else 
          this.sectionRefs["booking"].current.move("exit", "top");
          break;
      default:
        break;
    }
    setTimeout(() => {
      this.setState((prevState) => {
        return {
          inView: newSection,
          offView: ""
        }
      });
    }, 1000);
  }

  render(){
    return (
        <div className="App">
          <Home 
            changeSection={this.changeSection}
            ref={this.sectionRefs["home"]}  
            style={{display: this.state.inView === "home" || this.state.offView  === "home" ? "block" : "none"}}
            inView={this.state.inView === "home"}
            lang={this.state.lang}
            changeLang={this.changeLang}        
          />
          <Booking
            changeSection={this.changeSection}
            ref={this.sectionRefs["booking"]}
            style={{display: this.state.inView === "booking" || this.state.offView === "booking" ? "block" : "none"}}
            lang={this.state.lang}
          />
          <Menu 
            changeSection={this.changeSection}
            ref={this.sectionRefs["menu"]}
            style={{display: this.state.inView === "menu" || this.state.offView === "menu" ? "block" : "none"}}
            inView={this.state.inView === "menu"}
            lang={this.state.lang}
            changeLang={this.changeLang}        
          />
          <Thanks
            changeSection={this.changeSection}
            ref={this.sectionRefs["thanks"]}
            style={{display: this.state.inView === "booking" || this.state.inView === "thanks" ? "block" : "none"}}
            lang={this.state.lang}   
            changeLang={this.changeLang}        
          />
        </div>
    );
  }
}


