import React from 'react';

export default class Gallery extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            inView: 11,
            offView: null,
        }
        this.canSlide = true;
        this.gridRef = React.createRef();
        this.imagesRef = React.createRef();
        this.progressColumn = React.createRef();
        this.progressRow = React.createRef();
        this.menuRef = React.createRef();
        this.containerRef = React.createRef();
    }

    getIndexFromPosition = (position) => {
        let rest = parseInt((position / 10 + "").substring(0, 2));
        return ((rest - 1) * 6 + position % 10 - 1); 
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.offView !== null && this.state.offView === null) {
            const prevImg = this.imagesRef.current.children[this.getIndexFromPosition(prevState.inView)];
            const currImg = this.imagesRef.current.children[this.getIndexFromPosition(this.state.inView)];
            prevImg.style.zIndex = 0;
            currImg.style.zIndex = 1;
            currImg.style.left = 0;
            currImg.style.top = 0;
            currImg.classList.remove(currImg.classList.item(currImg.classList.length - 1)); 
        }
        
        if (prevState.inView !== this.state.inView) {
            this.gridRef.current.querySelector(".up").children[0].classList.toggle("not-visible", parseInt((this.state.inView / 10 + "").substring(0, 2)) === 1);
            this.gridRef.current.querySelector(".down").children[0].classList.toggle("not-visible", parseInt((this.state.inView / 10 + "").substring(0, 2)) === 4);
            this.gridRef.current.querySelector(".left").children[0].classList.toggle("not-visible", this.state.inView % 10 === 1);
            this.gridRef.current.querySelector(".right").children[0].classList.toggle("not-visible", this.state.inView % 10 === 6);
        }
    }

    componentDidMount(){
        let xDown = null;                                                        
        let yDown = null;

        function getTouches(evt) {
            return evt.touches;
        }                                                     

        function handleTouchStart(evt) {
            const firstTouch = getTouches(evt)[0];                                      
            xDown = firstTouch.clientX;                                      
            yDown = firstTouch.clientY;                                      
        };                                                

        let handleTouchMove = (evt) => {
            if (this.canSlide === true) {
                this.canSlide = false;
                if ( ! xDown || ! yDown ) {
                    return;
                }
                
                var xUp = evt.touches[0].clientX;                                    
                var yUp = evt.touches[0].clientY;
                
                var xDiff = xDown - xUp;
                var yDiff = yDown - yUp;
                
                if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
                    if ( xDiff > 0 ) {
                        this.changeImage("right");
                    } else {
                        this.changeImage("left");
                    }                       
                } else {
                    if ( yDiff > 0 ) {
                        this.changeImage("down")
                    } else { 
                        this.changeImage("up");
                    }                                                                 
                }

                setTimeout(() => {
                    this.canSlide = true;
                }, 1500);
            }
        }
            
        this.containerRef.current.addEventListener('touchstart', handleTouchStart, false);        
        this.containerRef.current.addEventListener('touchmove', handleTouchMove, false);

        setTimeout(() => {
            document.querySelector("i[data-uk-icon='chevron-up']").children[0].classList.add("not-visible");
            document.querySelector("i[data-uk-icon='chevron-left']").children[0].classList.add("not-visible");
            Array.from(document.querySelectorAll("i[data-uk-icon^='chevron']")).forEach(el => {
                el.children[0].addEventListener("click", () => {
                    if (this.canSlide) {
                        this.canSlide = false;
                        this.changeImage(el.classList.item(0));
                        setTimeout(() => {
                            this.canSlide = true;
                        }, 1500);
                    }
                }, false);
            });
        }, 1000);
        Array.from(this.gridRef.current.children).forEach((el) => {    
            if (el.classList.contains("gallery-grid-column")) {
                el.classList.add('full-height');
            } else if (el.classList.contains("gallery-grid-row")) {
                el.classList.add('full-width');
            }
        }); 
    }

    move = () => {
        this.containerRef.current.classList.remove("enter-top");
        this.containerRef.current.classList.add("enter-top");
        setTimeout(() => {
            this.containerRef.current.classList.remove("enter-top");
        }, 1000);
    }

    changeImage = (direction) => {
        let nextImg = null;
        let progress = "";
        let directionClass = (direction === "up" ? "top" : (direction === "down" ? "bottom" : direction));
        switch(direction) {
            case "right":
                progress = "row-extend";
                if (this.state.inView % 10 < 6) {
                    nextImg = this.state.inView + 1;
                }
                break;
            case "left":
                progress = "row";
                if (this.state.inView % 10 > 1) {
                    nextImg = this.state.inView - 1;
                }
                break;
            case "down":
                progress = "column-extend";
                if (this.state.inView / 10 < 4) {
                    nextImg = this.state.inView + 10;
                }
                break;
            case "up":
                progress = "column";
                if (this.state.inView / 10 >= 2) {
                    nextImg = this.state.inView - 10;
                }
                break;
            default:
                break;
        }

        if (nextImg !== null) {
            this.setState({ offView: nextImg });
            this.imagesRef.current.children[this.getIndexFromPosition(this.state.inView)].classList.add("get-big");
            this.imagesRef.current.children[this.getIndexFromPosition(nextImg)].style.zIndex = 2;
            this.imagesRef.current.children[this.getIndexFromPosition(nextImg)].classList.add(`enter-${directionClass}`);
            setTimeout(() => {
                this.imagesRef.current.children[this.getIndexFromPosition(this.state.inView)].classList.remove("get-big");
                this.setState({
                    inView: nextImg,
                    offView: null, 
                });
            }, 1000);

            Array.from(this.gridRef.current.children).forEach((el) => {    
                if (el.classList.contains("gallery-grid-column")) {
                    el.classList.remove('full-height');
                    setTimeout(() => {
                        el.style.top = "unset";
                        el.style.bottom = 0;
                        el.classList.add("full-height");
                    }, 1000)
                } else if (el.classList.contains("gallery-grid-row")) {
                    el.classList.remove('full-width');
                    setTimeout(() => {
                        el.style.left = "unset";
                        el.style.right = 0;
                        el.classList.add("full-width");
                    }, 1000);
                }
            });

            let progressColumnHeight = parseInt(window.getComputedStyle(this.progressColumn.current).getPropertyValue('height'));
            let progressRowWidth = parseInt(window.getComputedStyle(this.progressRow.current).getPropertyValue('width'));
            let heightScale = window.innerHeight / 4 + .5;
            let widthScale = window.innerWidth / 6 + .5;

            if (progress.includes("extend")) {
                
                if (progress.includes("column")) {
                    this.progressColumn.current.style.height = progressColumnHeight + heightScale + "px";
                    this.menuRef.current.style.marginTop = progressColumnHeight + "px";      
                } else {
                    this.progressRow.current.style.width = progressRowWidth + widthScale + "px";
                    this.menuRef.current.style.marginLeft = progressRowWidth + "px";
                }
            } else {
                if (progress.includes("column")) {
                    this.progressColumn.current.style.height = progressColumnHeight - heightScale + "px";
                    this.menuRef.current.style.marginTop = progressColumnHeight - 2 * heightScale + "px";
                } else {
                    this.progressRow.current.style.width = progressRowWidth - widthScale + "px";
                    this.menuRef.current.style.marginLeft = progressRowWidth - 2 * widthScale + "px";
                }
            }
        }
    }

    render(){
        return (
            <div className="gallery-page container" ref={this.containerRef} style={this.props.style}>
                <div className="gallery-grid uk-height-1-1 uk-width-1-1" ref={this.gridRef}>
                {
                    Array.from({length:5}, (el, idx) => {
                        return <div 
                            className="gallery-grid-column"
                            style={{
                                left: `${100 / 6 * (idx + 1)}%`,
                                transitionDelay:`.${idx * 1}s`
                            }}
                            >
                        </div>
                    })
                }
                {
                    Array.from({length:4}, (el, idx) => {
                        return <div 
                            className="gallery-grid-row"
                            style={{
                                top: `${100 / 4 * (idx + 1)}%`,
                                transitionDelay: `.${idx * 1}s`
                            }}
                            >
                        </div>
                    })
                }
                    <div 
                        className="logo-container"
                        onClick={() => {this.props.changeSection("home")}}
                    ></div>
                    <div className="menus" ref={this.menuRef}>
                        <h2 
                            className="menu-title first"
                            onClick={() => {this.props.changeSection("booking")}}
                        >
                        {   this.props.lang === "ro" ? "rezervări" : "book"}
                        </h2>
                        <h2 
                            className="menu-title second"
                            onClick={() => {this.props.changeSection("menu")}}
                        >{this.props.lang === "ro" ? "meniu" : "menu"}</h2>   
                    </div>
                    <div className="progress-row">
                        <span className="number">1</span>
                        <span className="number">2</span>
                        <span className="number">3</span>
                        <span className="number">4</span>
                        <span className="number">5</span>
                        <span className="number">6</span>
                        <span className="progress-bar" ref={this.progressRow}></span>
                    </div>
                    <div className="progress-column">
                        <span className="progress-bar" ref={this.progressColumn}></span>
                        <span className="number">1</span>
                        <span className="number">2</span>
                        <span className="number">3</span>
                        <span className="number">4</span>
                    </div>
                        <i data-uk-icon="chevron-up" className="up" /> 
                        <i data-uk-icon="chevron-left" className="left"></i> 
                        <i data-uk-icon="chevron-down" className="down"></i>
                        <i data-uk-icon="chevron-right" className="right"></i>
                </div>
                <div className="gallery-images" ref={this.imagesRef}>
                {
                    Array.from({length: 4}, (el, row) => {
                        return Array.from({length: 6}, (el, column) => {
                            let position = (row + 1) * 10 + column + 1;
                            return (
                                <div 
                                    className={`image gallery-${row + 1}-${column + 1}`}
                                    data-gallery-row={row + 1}
                                    data-gallery-column={column + 1}
                                    style={{
                                        backgroundImage: `url('images/gallery${row + 1}-${column + 1}.jpg')`,
                                        display: this.state.inView === position || this.state.offView ? "block" : "none",     
                                    }}
                                ></div>
                            )
                        });
                    })
                }
                </div>
            </div>
        )
    }
}