import React from 'react';

export default function Stamp (props) {
    return (
        <div 
            className={`stamp ${props.lang}`} 
            style={{backgroundImage: "url('images/stamp.png')"}}
            onClick={() => {props.changeLang(props.lang === "ro" ? "en" : "ro")}}
        >
        </div>
    )
}