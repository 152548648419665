import React from 'react';
import scrollHelp from '../utilities/scrollHelp';
import initialData from '../data/about.json';
import Stamp from '../components/Stamp';
import BarPagination from '../components/BarPagination';

let data;

export default class AboutPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            full: true,
            lang: props.lang,
        }
        this.pageRef = React.createRef();
        this.videoRef = React.createRef();
        this.videoContainerRef = React.createRef();
        this.videoElRef = React.createRef();
        this.canSlide = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.inView === true) {
            if (this.props.idx === 1 || this.props.idx === 2) {
                this.videoElRef.current.addEventListener("ended", (e) => {
                    this.videoElRef.current.nextSibling.play();
                    this.videoElRef.current.nextSibling.style.opacity = 1;
                }, false);
            } else if (this.props.idx !== 5) {
                this.videoElRef.current.addEventListener("ended", (e) => {
                    if (this.state.full === true) {
                        this.rescale("down");
                    }
                })
            }

            if (this.props.idx === 5) {
                if (this.state.full === true) {
                    this.videoContainerRef.current.addEventListener("click", () => {
                        this.pageRef.current.classList.add("overlay");
                        this.videoContainerRef.current.children[1].innerText = this.props.lang === "ro" ? "galerie" : "gallery";
                    });
                }
            }
        }
    }

    componentDidMount(){
        if ([1,3,4,5].includes(this.props.idx)) {
            if (this.props.idx !== 5 && this.props.idx !== 4) {
                this.rescale("up");
            } else {
                this.setState({ full: false });
            }
            if (this.props.idx !== 1) {
                this.pageRef.current.addEventListener('wheel', (event) => {
                    if (this.canSlide === true) {
                        this.canSlide = false;
                        if (this.pageRef.current.classList.contains("full")) {
                            if (scrollHelp(event) === 1) {
                                if (this.props.idx === 4) {
                                    this.props.nextSlide();
                                    setTimeout(() => {
                                        this.canSlide = true;
                                    }, 1000);
                                } else if (this.props.idx === 5) {
                                    this.pageRef.current.classList.add("overlay");
                                    this.videoContainerRef.current.children[1].innerText = this.props.lang === "ro" ? "galerie" : "gallery";
                                } else {
                                    this.rescale("down");
                                }
                            } else if (scrollHelp(event) === - 1 && this.props.idx === 4) {
                                this.rescale("down");
                            } else this.canSlide = true;
                        } else if (!this.pageRef.current.classList.contains("full")) { 
                            if (scrollHelp(event) === -1) {
                                this.rescale("up");
                            } else if (scrollHelp(event) === 1) {
                                if (this.props.idx === 5 || this.props.idx === 4) {
                                    this.rescale("up");    
                                } else {
                                    this.props.nextSlide();
                                }
                                setTimeout(() => {
                                    this.canSlide = true;
                                }, 1000);
                            }
                        }
                    }
                });
            } else {
                this.pageRef.current.addEventListener('wheel', (event) => {
                    if (scrollHelp(event) === 1 && this.canSlide === true) {
                        this.canSlide = false;
                        this.props.nextSlide();
                        setTimeout(() => {
                            this.canSlide = true;
                        }, 1000);
                    }
                });
            }
        } else {
            this.setState({ full: false })
            this.pageRef.current.addEventListener('wheel', (event) => {
                if (scrollHelp(event) === 1 && this.canSlide === true) {
                    this.canSlide = false;
                    this.props.nextSlide();
                    setTimeout(() => {
                        this.canSlide = true;
                    }, 1000);
                }
            });
        }
    }

    switchToGallery = () => {
        this.pageRef.current.classList.remove("about-5");
        this.pageRef.current.classList.add("about-gallery");
        this.pageRef.current.children[1].children[0].children[0].children[2].innerText = "gallery";
    }

    rescale = (direction) => {
        if (direction === "down") {
            this.videoRef.current.style.transform = "scale(1,1)";
            this.videoRef.current.style.top = "0px";
            this.pageRef.current.classList.remove("full");
            setTimeout(() => {
                this.setState({ full: false });
            }, 500);
        } else {
            let containerHeight = parseInt(window.getComputedStyle(this.videoContainerRef.current).getPropertyValue("height"));
            let videoHeight = parseInt(window.getComputedStyle(this.videoRef.current).getPropertyValue("width")) * 9 / 16;
            let topOffset = -videoHeight * 2 + containerHeight - 175 + "px";
            this.videoRef.current.style.transform = `scale(2, 2)`;
            this.videoRef.current.style.top = topOffset;
            this.pageRef.current.classList.add("full");
            setTimeout(() => {
                this.setState({ full: true });
            }, 500);
        }
        setTimeout(() => {
            this.canSlide = true;
        }, 1500);
    }

    move = () => {
        this.pageRef.current.classList.remove("enter-top");
        this.pageRef.current.classList.add("enter-top");
        setTimeout(() => {
            this.pageRef.current.classList.remove("enter-top");
        }, 1000);
    }

    render(){
        return (
            <div className={`about-page container about-${this.props.idx} ${this.props.idx === 5 || this.props.idx === 2 || this.props.idx === 4 ? "" : "full"} ${this.props.idx === 1 ? "first" : ""}`} 
                ref={this.pageRef} 
                style={this.props.style}
            >
                <div className="about-header">
                    <div
                        onClick={() => {this.props.onSectionClick("home")}} 
                        className="logo-container">
                    </div>
                    <button 
                        onClick={() => {this.props.onSectionClick("menu")}} 
                        className="button menu orange">
                        {this.props.lang === "ro" ? "meniu" : "menu"}
                    </button>
                    <button
                        onClick={() => {this.props.onSectionClick("booking")}} 
                        className="button book orange">
                        {this.props.lang === "ro" ? "rezervări" : "book"}
                    </button>
                </div>
                <div className="content" ref={this.videoContainerRef}>
                    <div className="text" >
                        <div className="inner">
                            <h3 className="about-copy-title">
                                {Object.values(initialData[this.props.lang])[this.props.idx - 1][0]}
                            </h3>
                            <p className="about-copy-text">
                            {
                                Object.values(initialData[this.props.lang])[this.props.idx - 1][1].includes("(newline) ") ?
                                <React.Fragment>
                                    {Object.values(initialData[this.props.lang])[this.props.idx - 1][1].split("(newline) ")[0]}
                                    <br/>
                                    <br/>
                                    {Object.values(initialData[this.props.lang])[this.props.idx - 1][1].split("(newline) ")[1]}
                                </React.Fragment> : Object.values(initialData[this.props.lang])[this.props.idx - 1][1]
                            }
                            </p>
                            <button 
                                onClick={this.props.idx !== 5 ? () => {this.props.nextSlide()} : () => {
                                    if (window.innerWidth < 1000) {
                                        if (this.pageRef.current.classList.contains("about-gallery")) {
                                            this.props.nextSlide();
                                        } else this.switchToGallery();
                                    } else this.rescale("up");
                                }}
                                className="about-button button">
                                {Object.values(initialData[this.props.lang])[this.props.idx - 1][2]}
                            </button>
                        </div>
                        <div 
                            className="video full" ref={this.videoRef}
                        >
                            <div className="video-margin">
                                <div className="video-scaler">
                                {
                                    this.props.inView ? 
                                    <video 
                                        allowFullScreen="" 
                                        frameBorder="0" 
                                        autoPlay={window.innerWidth < 1000 ? false : "autoplay" }
                                        loop={this.props.idx === 1 || this.props.idx === 2 ? false : true} 
                                        muted 
                                        src={`videos/about${this.props.idx}${this.state.full ? "-full" : ""}.mp4?v=2`} 
                                        ref={this.videoElRef}>
                                        <source type="video/mp4"/>
                                    </video> : ""
                                }
                                {
                                    this.props.idx === 1 || this.props.idx === 2 ? 
                                    <video 
                                        loop={true}
                                        muted
                                        src={`videos/about${this.props.idx}.1.mp4?v=2`}
                                        style={{display: window.innerWidth < 1000 ? "none" : "block", position: "absolute", left:0, top:0, opacity: 0}}
                                    >
                                        <source type="video/mp4"/>
                                    </video>: ""
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 
                        className="about-page-title"
                        onClick={this.props.idx === 5 ? (e) => {
                            this.props.nextSlide();
                        } : null}
                    >{Object.keys(initialData[this.props.lang])[this.props.idx - 1]}</h2>
                    <p className="text-pagination">
                        <span className="pagenumber">
                        0{this.props.idx}
                        </span>
                        <span className="pagename">
                        {Object.keys(initialData[this.props.lang])[this.props.idx - 1]}
                        </span>    
                    </p>
                </div>
                <div className="about-footer">
                    <div className="scroll-info">
                    <i data-uk-icon="chevron-right" className="right"
                        onClick={this.props.idx !== 5 ? () => {this.props.nextSlide()} : () => {
                            if (window.innerWidth < 1000) {
                                if (this.pageRef.current.classList.contains("about-gallery")) {
                                    this.props.nextSlide();
                                } else this.switchToGallery();
                            } else this.rescale("up");
                        }}
                    ><span>next</span></i>
                    </div>
                    <p className="footer-infos">
                    {   this.props.lang === "ro" ?
                        <React.Fragment>
                            bd. primaverii
                            <span className="separator">|</span>
                            nr. 19-21
                            <span className="separator">|</span>
                            bucurești
                        </React.Fragment> :   
                        <React.Fragment>
                            19-21
                            <span className="separator">|</span>
                            primaverii blvd.
                            <span className="separator">|</span>
                            bucharest
                        </React.Fragment>
                    }
                        <span className="separator">|</span>
                        021.568.3031
                        <span className="separator">|</span>
                        0758.758.750
                    </p>
                </div>
                <Stamp lang={this.props.lang} changeLang={this.props.changeLang}/>
                <BarPagination total={5} current={this.props.idx - 1} inView={this.props.inView}/>
            </div>
        )
    }
}