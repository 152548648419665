import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import App from './App';

export default class AppRouter extends Component{
    render(){
        return (
            <Router>
                <Route exact path="" render={() => <App lang="en" section="home" />} />
                <Route exact path="/ro" render={() => <App lang="ro" section="home" />} />
                <Route path="/burgeri-sandwichuri" render={() => <App lang="ro" section="menu"/>} />
                <Route path="/en/burgers-sandwiches" render={() => <App lang="en" section="menu" />} />
                <Route path="/rezervari" render={() => <App lang="ro" section="booking" />} />
                <Route path="/en/reserve-your-table" render={() => <App lang="en" section="booking" />} />
            </Router>
        )
    }
}